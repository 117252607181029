body, html {
	font-family: Roboto, sans-serif;  
}

// h1, h2, h3, h4, h5, h6 {
// 	font-family: Roboto, sans-serif;
// 	font-weight: 800;    
// }

p, a, li, span, div {
	font-family: Roboto, sans-serif;  
}

.nav-logo {
	@include media-breakpoint-up(m) {
		top: 0;
		max-width: rem(250px);
	}

	@include media-breakpoint-up(l) {
		top: rem(36px);
		max-width: rem(300px);
	}
}

@include media-breakpoint-down(m) {
	.nav-wrapper {
		background: $background-light;

		.icon-link,
		.search__field,
		.sidenav-trigger,
		.search__submit,
		.webradio-title,
		.icon-play,
		.icon-stack .icon {
			color: $primary-color;
		}

		.search__field--focus,
		.search__field--active {
			background: $background-light;
			border-bottom: 1px solid $primary-color;
		}
	}
}


@include media-breakpoint-down(m) {
	.nav-wrapper {
		background: $background-light;
		
		.icon-link,
		.search__field,
		.sidenav-trigger,
		.search__submit,
		.webradio-title,
		.icon-play,
		.icon-stack .icon {
			color: $primary-color;
		}

		.search__field--focus,
		.search__field--active {
			background: $background-light;
			border-bottom: 1px solid $primary-color;
		}
	}
}

.nav-mobile__lower {
	background-color: white !important;
}

.nav-mobile__lower .nav-info-icon .icon-stack i {
	color: $primary-color !important;
}

.nav .nav-mobile__lower .nav-info-icon .mdi {
	color: $primary-color !important;
}

.nav-mobile__lower .webradio-play i {
color: $primary-color;
}

.nav-mobile__lower .webradio-title span {
	color: $primary-color;
}

.nav-mobile__lower .webradio-title {
	margin-top: 8px;
}

.info-contact-box {
	display: flex !important;
	.col {
		padding: 0;
	}
	p {
		color: $primary-color;
	}
}

.infobox__container .contact__form .input-field {
	margin: 0 !important;
}

// nav social media links
.nav-social-media-links {
	padding-left: rem(14px);

	@include media-breakpoint-up(xl) {
		display: flex !important;
	}

	a {
		margin-left: 1.125rem;
		position: relative;
		overflow: hidden;
		display: block;
		height: 48px;
		width: 48px;

		.mdi {
			justify-content: center;
			align-items: center;
			display: flex;
			color: white;
			line-height: 2.5rem;
			font-size: 2.5rem;
			height: 48px;
			width: 48px;
		}
	}
}

.ad__wrapper--wallpaper {
	display: block !important;
}

.footer__skyline.show-footer-image {
    display: none;
}
